import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "966px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/95737783f8bec3c93389e0152b17c787/5bf79/dread.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.98455598455598%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACpklEQVQozxXL+08ScQAA8NvEO847jnt+zzvuBEHgEARfPAwkRSaFSctWVvNRoKaFipAarvm2h5XlYz00za3WXOnsYZtN3VprNX/pl35p65fWX9L6/P6B+nE4g8MDGJzB4DURfc2pFo3y+VDwisAHIegsqx2msD2B+KWn33LEBk3s8vm/rfofIvtSS0BjGmSRhGcJeF6L7onIT5M65XMyRjkj8QGzo8Nff1WLr6uhTUr1F+S+kbmbAvjOaA9Z7SFDQPMk8oKCt2hkg0ZGGc2+AsYHjjZdsF2uq34Vj/0Zalm7ng43NLefTuwY5V5cFcLRrwA/BJovLA6tA3ydzcto1VkKjSI5M9XBMrfpXLw6ubR87/GJvc+96dGO6PP3iW9PL13r4nNhAVY9YPMmRHoNaKAlmR0uFFsYQo8iBoJqbYtTIpYcjEfTU11DFxMdIbMv4utp69vPzNzvbSRJCFEDDCvA8pwYCtXwXBkAgGZYgtRQfCASLSoW+1LtsdSN4zPLDa3J/omHkyvvvJ2ZcFOLAYUZlmNIGsE0ehyDSMDzgo6hWZKkACe4vEf8NUF3bbg+XNsTCZV4os3d052pu0WKh6QYQdbrREkURMDxPM1CAPBGQ2FpZbUkF1IU5/XXj4yMDqYHswPp2bGpqck749Nzt+YejWSz4bpjVrOi2ErMikMnygIvQhTF8rxod/nKfWFJMgUCoScrq9u7n3oyQ4lkauvDx4VnqzsHB5tb27GTZ4xGxe0NlpZXFcgGXb4Ooun/uUBvsdpcdqfHVub3WBxzrorbLs+wwZK0Oqa9gQVfMFYXsZd63ZUBb1VNpSdgtdp0ggQRBEnRnFRQpCh2k9FssDjtJnszTDSq0FMqNJyjbsjFuklglU1GxWlRXE5nRXGxQ9JJtJb+B/YGo46Jp+JOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Metroid Dread Title",
            "title": "Metroid Dread Title",
            "src": "/static/95737783f8bec3c93389e0152b17c787/5bf79/dread.png",
            "srcSet": ["/static/95737783f8bec3c93389e0152b17c787/a2ead/dread.png 259w", "/static/95737783f8bec3c93389e0152b17c787/6b9fd/dread.png 518w", "/static/95737783f8bec3c93389e0152b17c787/5bf79/dread.png 966w"],
            "sizes": "(max-width: 966px) 100vw, 966px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Metroid Dread is the story finale to the metroid saga that began all the way back in 1986. Metroidvanias are probably my favorite genre after RPGs, so I was definitely excited to pick this one up. How does it stack up against it's predecessors?`}</p>
    <h4>{`Pro: It's a new Metroid`}</h4>
    <p>{`This may sound dumb, but since it's been nearly 20 years since we had a mainline sequel to the series, it feels like a bigger deal. The Metroid Prime series took over in the interim with it's shiny FPS perspective, but Dread returns to the tried and true 2D platforming model, even if it's a slick 3D game under hood. `}</p>
    <h4>{`Pro: The Dark Souls of Metroid`}</h4>
    <p>{`This is without a doubt the hardest Metroid game I've ever played. For some that may be a point against it, but it did give me a great feeling of accomplishment when I took down Raven Beak after the twelfth attempt. I think every boss and mini-boss killed me at least once during my first play-through.`}</p>
    <h4>{`Pro: Tons of new items and a fake out`}</h4>
    <p>{`Dread added a bunch of new stuff to play with on your journey to the surface. My favorite is the Flash Shift which allows you to dash up to twice mid air. But we also got a cloaking device, a new way to shoot missiles, a mini space jump, and a new bomb. Beyond that they tweaked how some of the items work. Your missiles just become power missile when you get the upgrade, no need to select them separately. You can now shine-spark while in morph ball. The only thing that felt off to me was the grapple beam, which just didn't feel as good as it did in Super Metroid.`}</p>
    <p>{`Got a quick question for you? What's the first upgrade you get in a Metroid game? Morph ball right? Not this time. It's not a huge change and really doesn't affect anything at all, but I was a little mind blown when I got the charge beam first.`}</p>
    <h4>{`Pro: `}<strong parentName="h4">{`Spoiler Warning`}</strong></h4>
    <p>{`Actual live Chozos! They've been "present" in every game, but mostly as statues. Here we get to meet two live ones, and Samus even speaks Chozo (I'm guessing that's the name of the language). You do get a fair amount of lore dumping in the game too. This is nice because unless you've followed along in some of the other spinoffs or read the manga, you might not know some of this stuff. It was all new to me though and while the story will not set the world on fire, that's never the point of these games.`}</p>
    <h4>{`Con: EMMIs`}</h4>
    <p>{`I've played through this game multiple times now trying to push my time as low as it can get, so they bother me less than they did the first play-through, but I still think they make for poor game play. `}</p>
    <p>{`When you enter certain zones in the game you `}<em parentName="p">{`will`}</em>{` be chased down by one of these things and if they catch you, you die. OK, you have two chances to prevent that, but the timing for the counter is random so unless you have lightning reflexes you will die 99% of the time. The penalty isn't too great, as you just go back to the start of the EMMI zone, but your first time through you will be repeating this loop a bunch.`}</p>
    <p>{`From a story point of view, it does make sense why they can kill you instantly, but it remains super aggravating to get caught by one of these guys and fail and have to go back to the start of the zone. It probably would have been better if they just did a lot of damage and then if you're still alive Samus squirms/shoots her way out and you can try again. At least that way your e-tanks could mean something against them.`}</p>
    <h4>{`Con: The controls`}</h4>
    <p>{`This is something you get used to after you've been through the game a bunch, but holy moly, do you have to contort your hand at times to do want you want. The grapple beam, which I alluded to above, requires a two button combination to get working and so you can't easily Tarzan across the areas that are clearly meant to be swung across. Luckily these areas are few and far between, and only one is necessary.`}</p>
    <h4>{`Overall Impressions`}</h4>
    <p>{`I think the game is great. It's really challenging the first time, and still fun on multiple attempts. If I had to rank it amongst the 2D Metroids, I'd probably put it number two, after Super Metroid. I'm no speed runner but I do enjoy trying to complete every Metroid game as fast as possible. Currently I'm just above the three hour mark and if I can break that, I'll consider myself satisfied.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/59d1a47afed37908f09f734312173c31/21b4d/fastest.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.37065637065637%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABk0lEQVQoz1WS2U7CQBSG+wLqdGbaIrSdTovTsrSIsrQWBISoqGDQoCYuN8YL3/8FPNNSwOTLn1m+k5xZFML8DU4g04ZxgJ1AZT5iger4mAls+zqvaU4NHMokme8ruFKVlPP0NlNLeO04TC79ZCTOk0a7y4MIH3ukUs3JfQXrTgFXIQ1+VOJm1L9Yvc6/fm5WT58f763hFSlzYoDAsCEdkGGgqJqdYUEiCmkhUxy2Uncwu5rHi5E4m87UqIsrLgYBTD0jq1IQrexRhjxkdRqm88fl73v0/RJP1mu9M0AlB2kbYWvuilVq5oMDO6DNJJ0uXu87P2+NycNSCxNkQLG5VyxR8obVvB/NwgajtkDNuNdL7y5P+8OxezbGdh3OqWqsOGPRNs4uCSAlFy6MHvPBeLZ8Xs9u58Pp3fngNh5e99PJaScFAWSSyTnFU+3ewHP8yK21iHVCTFjxtkKGt3mnspQV+Tf2oDyg8jP4lNcIkH0MXOwiW3hRrx2PgnasuXWFVJv70JM8w+0K/rcVwpYuIk1I4Q/G71bWbNoo0QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Current best time",
            "title": "Current best time",
            "src": "/static/59d1a47afed37908f09f734312173c31/e3189/fastest.png",
            "srcSet": ["/static/59d1a47afed37908f09f734312173c31/a2ead/fastest.png 259w", "/static/59d1a47afed37908f09f734312173c31/6b9fd/fastest.png 518w", "/static/59d1a47afed37908f09f734312173c31/e3189/fastest.png 1035w", "/static/59d1a47afed37908f09f734312173c31/21b4d/fastest.png 1280w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      